import React, { useState, useEffect } from 'react';

const BonLookTiers = ({ lang }) => {
  const [showTiers, setShowTiers] = useState(false);

  const copyStrings = {
    en: {
      heading: 'What’s my Trendsetter tier?',
      tableHeading: 'Benefits',
      tableColHeader1: 'Influencer',
      tableColDesc1: 'Free to join',
      tableColHeader2: 'Muse',
      tableColDesc2: '2 pairs and spend $250+ a year',
      tableColHeader3: 'Icon',
      tableColDesc3: '3 pairs and spend $500+ a year',
      tableRowDesc1: 'Free shipping on all orders',
      tableRowDesc2: 'Birthday gift (Promo Code)',
      tableRowDesc3: 'Invitations to special events',
      tableRowDesc4: 'Access to exclusive contests',
      tableRowDesc5: 'Access to special product and promotion launches, and behind the scene content',
      tableRowDesc6: 'Access to Trendsetter Weekends offer',
      tableRowDesc7: 'Access to brand partnership promotions',
      tableRowDesc8: 'Offer on next order',
      tableRowDesc9: 'Free pair (valued up to $150) a year',
    },
    fr: {
      heading: 'Quel est mon niveau Trendsetter?',
      tableHeading: 'Avantages',
      tableColHeader1: 'Influenceur',
      tableColDesc1: 'Adhésion gratuite',
      tableColHeader2: 'Muse',
      tableColDesc2: 'Achetez 2 paires et dépensez plus de 250 $ par année',
      tableColHeader3: 'Icône',
      tableColDesc3: 'Achetez 3 paires et dépensez plus de 500 $ par année',
      tableRowDesc1: 'Livraison gratuite sur toutes les commandes',
      tableRowDesc2: 'Cadeau d’anniversaire (Code promo)',
      tableRowDesc3: 'Invitations à des événements spéciaux',
      tableRowDesc4: 'Concours exclusifs',
      tableRowDesc5: "Accès à des lancements spéciaux de produits et de promotions, ainsi qu'à du contenu en coulisses",
      tableRowDesc6: 'Offre spéciale durant les journées Trendsetter',
      tableRowDesc7: 'Promotions de marques partenaires',
      tableRowDesc8: 'Offre spéciale durant les journées Trendsetter',
      tableRowDesc9: 'Paire gratuite (valeur de 150$) par année',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="custom-tiers__breakdown">
      <div className="custom-tiers__inner">
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/ethos/v2/ui/icon-down-arrow.svg" alt="What’s my Trendsetter tier?" /></a>
        </div>
        {showTiers
        && (
        <div className="custom-tiers__table">
          <table>
            <tr>
              <th>{copyStrings[lang].tableHeading}</th>
              <th>
                {copyStrings[lang].tableColHeader1}
                <span>{copyStrings[lang].tableColDesc1}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader2}
                <span>{copyStrings[lang].tableColDesc2}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader3}
                <span>{copyStrings[lang].tableColDesc3}</span>
              </th>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc1}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc2}</td>
              <td>
                &middot;
                <p style={{ fontSize: '16px' }}>20$ offer</p>
              </td>
              <td>
                &middot;
                <p style={{ fontSize: '16px' }}>25$ offer</p>
              </td>
              <td>
                &middot;
                <p style={{ fontSize: '16px' }}>30$ offer</p>
              </td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc3}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc4}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc5}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc6}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc7}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc8}</td>
              <td></td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc9}</td>
              <td></td>
              <td></td>
              <td>&middot;</td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default BonLookTiers;
