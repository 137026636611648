import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Polls {
  constructor() {
    this.apiUrl = process.env.SERVER_URL;
  }

  submitPoll(token, payload) {
    return Ajax.post(this.apiUrl + 'poll/' + process.env.ASSET_ID + '/submit/response', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }
}

export default new Polls();
